function setZoom() {
	//--- add a colorbox wrapper for custom alerts
	var colorwrap = $("<div />").css({display: "none"});
	$("<div />").attr("id", "inline_alert").appendTo(colorwrap);
	var trigger = $("<a />").attr("href", "#").attr("class", "triggerme").attr("id", "triggerme").css({display: "none"});
	$('body').append(colorwrap).append(trigger);

	//--- setup the colorboxzoom itself
	$('a.zoom').colorbox({
		opacity : 0.5,
		maxWidth:'98%',
		maxHeight:'98%',
		current : "{current}/{total}"
	});
	$("a#mlr-alert").colorbox({opacity:0.5, width:"400px", inline:true, href:"#inline_alert"});

	$("a#triggerme").colorbox({
		opacity: 0.5,
		maxWidth:'98%',
		maxHeight:'98%',
		inline: true,
		href: "#inline_alert",
		onClosed: function(){$('#inline_alert').html('');}
	});

}

function scrollToClass(cls){
	$('html,body').animate({scrollTop: $("."+cls).offset().top - 150},1200);
}

Storage.prototype.setObj = function(key, obj) {
	return this.setItem(key, JSON.stringify(obj))
}
Storage.prototype.getObj = function(key) {
	return JSON.parse(this.getItem(key))
}

function initJeromeJs() {

  //window.alert( $('.js-mh').length );

  setZoom();

  var scrollheight = 300;

  $(window).scroll(function(){

    if ($(this).scrollTop() > scrollheight) {
      $('body').addClass('scrolled');
      $('.back-to-top').addClass('show');
    } else {
      $('body').removeClass('scrolled');
      $('.back-to-top').removeClass('show');
    }

  }).scroll();


  // Mainnav action
  $('.nav-toggle').on('click',function(e){

    if($('body').hasClass('shownav')){
      $('.nav-toggle').removeClass('open');
      $('body').removeClass('shownav no-scroll');
      $('.site-header').removeClass('navopen');
    }else{
      $('.nav-toggle').addClass('open');
      $('body').addClass('shownav no-scroll');
      $('.site-header').addClass('navopen');
    }

    e.preventDefault();

  });

  $('.mobile-navxxx .mainnav a').on('click',function(e){

    $('.nav-toggle').trigger('click');
    e.preventDefault();

  });


  $('body').on('click','.toggle-search',function(e){

    if($('.search-layer').hasClass('show')){

      $('.c-search-box #dosearch').focus();

    }else{

    }

    e.preventDefault();

  });


  $(window).resize(function(){

    var w = $(window).width();

    if(w < 992){

      $('body').removeClass('isdesktop').addClass('ismobile');

    }else{

      $('body').removeClass('ismobile').addClass('isdesktop');

    }

  }).resize();

  //$('.button').wrapInner('<span class="btn-inner" />');
  $('.button .btn-inner').append('<i class="icon-skg-pijl-right" />');
  $('.btn-arrow').append('<i class="icon-skg-pijl-right" />');

  $('.scrollto').click(function(e){

    var scrollto_cls = $(this).data('scrollto');
    scrollToClass(scrollto_cls);
    e.preventDefault();

  });


  $('body').on('click','.back-to-top', function(e){
    window.scrollTo({top: 0, behavior: 'smooth'});

    e.preventDefault();
  });


  function setSplit (container) {

    $(container + ' .js-split').each( function(){
      var elem = $(this);
      var words = elem.text().split(" ");
      // var split = Math.round(words.length / 2);
      var split = elem.data('split') || 1 ;
      var text = '<span class="split-1">' + words.splice(0, split).join(' ') + '</span> <span class="split-2">' + words.join(' ') + '</span>';

      elem.html(text);
    });

  }
  setSplit('body');



  $('.article:has(".goto")').mouseenter(function(){
    $(this).addClass('hover');
  }).mouseleave(function(){
    $(this).removeClass('hover');
  }).click(function(e){
    window.location.href= $(this).find('.goto').attr('href');
    e.preventDefault();
  }).css('cursor','pointer');


  $('.list-row:has(".goto")').mouseenter(function(){
    $(this).addClass('hover');
  }).mouseleave(function(){
    $(this).removeClass('hover');
  }).css('cursor','pointer');


  $('.js-mh-3').matchHeight();
  $('.js-mh-2').matchHeight();
  $('.js-mh').matchHeight();

  $('.js-mh-norow').matchHeight({
    byRow: false
  });

  $(".btn-video").colorbox({
    opacity: 0.5,
    iframe:true,
    innerWidth:800,
    innerHeight:488,
    maxWidth:'98%',
    maxHeight:'98%',
    close: '<i class="icon-kruisje"></i>'
  });

  $('.site-footer .links a').prepend('<i class="icon-skg-pijl-right" /> ');


  function setDuotone (container) {

    $(container + ' .js-duotone').each(function(){

      var el = $(this);
      var words = el.text().split(" ");

      // geen duotone als text minder is als X woorden.
      if(words.length < 1) {
        return;
      }

      var half = Math.round(words.length / 2);
      var text = words.splice(0, half).join(' ') + ' <span class="duotone">' + words.join(' ') + '</span>';

      el.html(text);

    });

  }
  setDuotone('body');


  $('.article-output table').each(function(){

    var _table = $(this);

    _table.addClass('scrolltable');

    var cols = _table.find("tr:last td").length;

    if(cols>4){

      _table.addClass('large-table');

    }

  });


  $('.scrolltable').wrap('<div class="table-scroll-container" />');
  $('.table-scroll-container').after('<div class="scrollpointer"><-- scroll --></div>');


  $('.school-filter input:checkbox').on('change',function(){

    _this = $(this).parent();

    if($(this).is(':checked')){
      _this.addClass('checked');
    }else{
      _this.removeClass('checked');
    }

  });

  // $('body').on('click','.btn-favorite', function(e){
  //
  //   var schoolid = $(this).data('school');
  //
  //   if (localStorage.hasOwnProperty("fav-schools")) {
  //
  //     var ids = localStorage.getObj('fav-schools');
  //
  //     //window.alert(ids);
  //
  //   }else{
  //
  //     var ids = new Array();
  //
  //   }
  //
  //   //ids.push(schoolid);
  //
  //   localStorage.setObj('fav-schools', ids);
  //
  //   $(this).html('<i class="icon-heart" />').addClass('do-pulse');
  //   e.preventDefault();
  //
  // });


  // $('.slick-articles').slick({
  // 	autoplay: false,
  // 	infinite: true,
  // 	arrows: false,
  // 	dots: true,
  // 	fade: false,
  // 	autoplaySpeed: 5000,
  // 	speed: 800,
  // 	slidesToShow: 4,
  // 	slidesToScroll: 4,
  // 	pauseOnHover: false,
  // 	pauseOnFocus: false,
  // 	responsive: [
  // 		{
  // 			breakpoint: 1280,
  // 			settings: {
  // 				slidesToShow: 3,
  // 				slidesToScroll: 3,
  // 				speed: 300,
  // 			}
  // 		},
  // 		{
  // 			breakpoint: 991,
  // 			settings: {
  // 				slidesToShow: 2,
  // 				slidesToScroll: 2,
  // 				speed: 300,
  // 			}
  // 		},
  // 		{
  // 			breakpoint: 520,
  // 			settings: {
  // 				slidesToShow: 1,
  // 				slidesToScroll: 1,
  // 				speed: 300,
  // 			}
  // 		}
  // 	]
  // });


  // $('.slick-images').slick({
  // 	autoplay: false,
  // 	infinite: true,
  // 	arrows: false,
  // 	dots: true,
  // 	fade: false,
  // 	autoplaySpeed: 5000,
  // 	speed: 800,
  // 	slidesToShow: 1,
  // 	slidesToScroll: 1,
  // 	pauseOnHover: false,
  // 	pauseOnFocus: false
  // });


  // if($('.slick-scholen').length) {

    // $('.slick-scholen').slick({
    //   autoplay: false,
    //   infinite: false,
    //   arrows: false,
    //   dots: true,
    //   fade: false,
    //   autoplaySpeed: 5000,
    //   speed: 500,
    //   slidesToShow: 4,
    //   slidesToScroll: 1,
    //   pauseOnHover: false,
    //   pauseOnFocus: false,
    //   prevArrow: '<a href="#" data-role="none" class="slick-prev" aria-label="Vorige" tabindex="0" role="button"><i class="icon-right-small"></i></a>',
    //   nextArrow: '<a href="#" data-role="none" class="slick-next" aria-label="Volgende" tabindex="0" role="button"><i class="icon-right-small"></i></a>',
    //   responsive: [
    //     {
    //       breakpoint: 1280,
    //       settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         speed: 300,
    //       }
    //     },
    //     {
    //       breakpoint: 991,
    //       settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 1,
    //         speed: 300,
    //         arrows: false,
    //         dots: true
    //       }
    //     },
    //     {
    //       breakpoint: 520,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         speed: 300,
    //         arrows: false,
    //         dots: true
    //       }
    //     }
    //   ]
    // });

    //var dummyslidecontent = $('.section-mijn-scholen .attribute-layer').html();
    //var dummyslide = '<div class="item"><div class="inner">'+ dummyslidecontent +'</div></div>';

    //$('.slick-scholen').slick('slickGoTo', 1, true);

  // }


  $('.infotip').tooltipster({
    maxWidth: 320,
    trigger: 'click'
  });


  // function doSearch() {
  //
  //   var keyword = $('#dosearch').val();
  //
  //   if (keyword.length > 1) {
  //
  //     $('.search-result-output').html('<div class="c-ajax-loader"></div>');
  //
  //     SCHOLEN
      // $.ajax({
      //   type: 'GET',
      //   url: 'ajax/search-scholen.php',
      //   dataType: 'html',
      //   data: 'keyword=' + encodeURIComponent(keyword),
      //   beforeSend: function () {
      //
      //
      //   },
      //   complete: function () {
      //
      //   },
      //   success: function (html) {
      //
      //     setTimeout(function () {
      //
      //       $('.search-result-schools').html(html);
      //
      //     }, 400);

        // }
      // });
      //
      // ARTICLES
      // $.ajax({
      //   type: 'GET',
      //   url: 'ajax/search-artikelen.php',
      //   dataType: 'html',
      //   data: 'keyword=' + encodeURIComponent(keyword),
      //   beforeSend: function () {
      //
      //
      //   },
      //   complete: function () {
      //
      //   },
      //   success: function (html) {
      //
      //     setTimeout(function () {
      //
      //       $('.search-result-articles').html(html);
      //
      //     }, 800);
      //
      //   }
      // });
    //
    // } else {
    //
    //   $('.search-result-output').html('Type een zoekwoord in de zoekbalk');
    //
    // }
  //
  // }

  // $('#dosearch').keyup(function (e) {

    // doSearch();

  // });
}

function initJeromeAccordeon() {
  // ACCORDEON
  $('.js-accordeon .answer').hide();

  $('.js-accordeon .question').click(function() {

    $('.js-accordeon .question').removeClass('open').parent().find('.answer').slideUp(600);

    var _par = $(this).parent();
    var _this = $(this);
    _this.addClass('open');

    var state = 'wasclosed';

    if(_this.next('.answer').is(':visible')){
      _par.removeClass('active');
      _this.removeClass('open');
      state = 'wasopen';
    }else{
      _par.addClass('active');
      _this.addClass('open');
    }

    if(state == 'wasclosed'){
      _this.next('.answer').slideDown(600,function(){});
    }

  });
}

jQuery(document).ready(initJeromeJs);

function initSchoolFavoriteSlick() {
  $('.slick-scholen').slick({
    autoplay: false,
    infinite: false,
    arrows: false,
    dots: true,
    fade: false,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    pauseOnHover: false,
    pauseOnFocus: false,
    prevArrow: '<a href="#" data-role="none" class="slick-prev" aria-label="Vorige" tabindex="0" role="button"><i class="icon-right-small"></i></a>',
    nextArrow: '<a href="#" data-role="none" class="slick-next" aria-label="Volgende" tabindex="0" role="button"><i class="icon-right-small"></i></a>',
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          speed: 300,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 300,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 300,
          arrows: false,
          dots: true
        }
      }
    ]
  });
}
