$(document).ready(function() {

	// $('.filter-layer-toggle').on('click',function(e){
  //
	// 	var $filterbox = $('.filter-layer');
  //
	// 	if($filterbox.hasClass('open')){
	// 		$filterbox.removeClass('open');
	// 		$('body').removeClass('no-scroll show-filters');
	// 	}else{
	// 		$filterbox.addClass('open');
	// 		$('body').addClass('no-scroll show-filters');
	// 	}
  //
	// 	e.preventDefault();
  //
	// });


	// $('.filter-toggle').on('click',function(e){
  //
	// 	var _this = $(this).parent();
	// 	//_this.addClass('open').next().addClass('open');
  //
	// 	if(_this.next('.filter-options').is(':visible')){
	// 		$(this).removeClass('active');
	// 		_this.removeClass('open').next().removeClass('open');
	// 	}else{
	// 		$(this).addClass('active');
	// 		_this.addClass('open').next().addClass('open');
	// 	}
  //
	// 	$.fn.matchHeight._update();
  //
	// 	e.preventDefault();
  //
	// });

	function setChecked() {
		$('.filter-box input[type=checkbox]:checked').parent().addClass('checked');
	}
	setChecked();

	function setSelectedLabels(){

		// $('.filter-box').each(function(){

			// var _this = $(this);

			// var numberOfChecked = $('input:checkbox:checked',_this).length;

			// if(numberOfChecked > 0){

				// _this.find('.number-selected').html(numberOfChecked).show();

			// }else{

				// _this.find('.number-selected').html('').hide();

			// }

		// });

	}
	setSelectedLabels();

	$('.filter-box input:checkbox').on('change',function(){

		_this = $(this).parent();

		if($(this).is(':checked')){
			_this.addClass('checked');
		}else{
			_this.removeClass('checked');
		}

		setSelectedLabels();

	});



	$('.toggle-map').on('click',function(e){

		if($('.section-finder').hasClass('fullscreen')){
			$('.section-finder').removeClass('fullscreen');
		}else{
			$('.section-finder').addClass('fullscreen');
		}

		e.preventDefault();

	});

	$('.show-list').on('click',function(e){

		$('.show-list').removeClass('active');
		$('.show-map').addClass('active');

		$('.section-finder .col-right').hide();
		$('.section-finder .box-right').show();

		e.preventDefault();

	});

});
